import React from 'react';
import cx from 'clsx';
import { CARD_TYPES } from '@nike/ciclp-redux-app';
import ImageSlide from './slideComponents/imageSlide';
import VideoSlide from './slideComponents/videoSlide';
import { CardLink } from '../cardLink';
import styles from './slideshowSlide.styl';
import { gallerySlideShape } from '../../shapes';
import { setCustomTextColor } from '../../helpers/setCustomTextColor';

const createMarkup = body => ({
  __html: body,
});

const getSlideComponent = slide => {
  switch (slide?.containerType) {
    case CARD_TYPES.VIDEO:
      return VideoSlide;
    case CARD_TYPES.IMAGE:
      return ImageSlide;
    default:
      return ImageSlide;
  }
};

const SlideshowSlide = ({
  analyticsItemId,
  sectionHeadlineTitle,
  cardId,
  isVideoCard,
  cardLinkMemberOnly,
  ...slide
}) => {
  const Slide = getSlideComponent(slide);
  const caption = (
    <figcaption className={styles.slideTextWrapper}>
      {slide.titleProps?.text && (
        <h3
          data-qa="title"
          className={cx(['body-2', 'text-color-secondary', styles.slideTitle])}
          dangerouslySetInnerHTML={createMarkup(slide.titleProps?.text)}
          style={slide?.titleProps && setCustomTextColor(slide.titleProps)}
        />
      )}
    </figcaption>
  );

  return (
    <figure
      data-analytics-placement-id={analyticsItemId}
      data-analytics-has-landmark="true"
      data-qa="carousel-slideshow-item"
    >
      {slide.counter}
      <Slide {...slide} caption={caption} />
      {slide?.cardLinkUrl && (
        <CardLink
          memberOnly={cardLinkMemberOnly}
          draggable={false}
          actionId={slide.cardLinkId}
          destinationUrl={slide.cardLinkUrl}
          openInNewTab={slide.openInNewTab}
          renderAs="a"
          ariaLabel={slide.titleProps?.text}
        />
      )}
    </figure>
  );
};

SlideshowSlide.propTypes = gallerySlideShape;

export default SlideshowSlide;
