export const CarouselSetup = {
  slidesPerView: 'auto',
  freeMode: true,
  freeModeMomentumBounce: false,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
  threshold: 15, // avoid accidental touch event when you click on prev/next button
  updateOnImagesReady: false,
  mousewheel: {
    forceToAxis: true,
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
    snapOnRelease: false,
  },
  breakpoints: {
    959: {
      slidesOffsetAfter: 24,
      slidesOffsetBefore: 24,
    },
    1919: {
      slidesOffsetAfter: 48,
      slidesOffsetBefore: 48,
    },
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
};
