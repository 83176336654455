import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import ImageMedia from '../../imageMedia';
import {
  imageHeightResolver,
  getImageAspectRatio,
} from '../../imageMedia/helpers';
import imageHeights from '../../../constants/imageHeights.styl';
import styles from '../slideshowSlide.styl';
import { gallerySlideShape } from '../../../shapes';
import { useDimensionTypeContext } from '../../../context';

const MAX_VARIABLE_WIDTH = 1328;

const setSlideWidth = (isReady, keepImageAspectRatio, adjustedSlideWidth) => {
  switch (true) {
    case !isReady && keepImageAspectRatio:
      return { width: adjustedSlideWidth };
    case keepImageAspectRatio:
      return { maxWidth: adjustedSlideWidth };
    default:
      return {};
  }
};

const ImageSlide = ({ caption, ...slide }) => {
  const { isPortrait, dimensionType } = useDimensionTypeContext();
  const aspectRatio = getImageAspectRatio({
    assetsAspectRatios: slide.assetsAspectRatios,
    isPortrait,
  });
  const [isSlideReady, setIsSlideReady] = useState(false);
  const handleSlideReady = () => {
    setIsSlideReady(true);
  };

  const adjustedSlideWidth = Math.min(
    imageHeightResolver({
      imageHeight: slide.imageHeight,
      dimensionType,
    }) * aspectRatio,
    MAX_VARIABLE_WIDTH,
  );

  return (
    <div
      className={cx([slide.keepImageAspectRatio && styles.keepAspectRatio])}
      data-width={adjustedSlideWidth}
      data-aspect-ratio={aspectRatio}
      data-keep={slide.keepImageAspectRatio}
      style={setSlideWidth(
        isSlideReady,
        slide.keepImageAspectRatio,
        adjustedSlideWidth,
      )}
    >
      <ImageMedia
        {...slide}
        onImageLoad={handleSlideReady}
        draggable={false}
        customClass={slide.imageHeight && imageHeights[slide.imageHeight]}
      />
      {caption}
    </div>
  );
};

ImageSlide.propTypes = {
  loop: PropTypes.bool,
  keepImageAspectRatio: PropTypes.bool,
  caption: PropTypes.element,
  ...gallerySlideShape,
};

export default ImageSlide;
